import { Download } from "@mui/icons-material";
import CircularProgress from "@mui/material/CircularProgress";
import * as React from "react";
import useImageDownload from "../../../../../extensions/guide/hooks/useImageDownload";
import { MediaAsset } from "../../../../../models/MediaAsset";
import {IconButton, Tooltip} from "@mui/material";
import {useTranslation} from "react-i18next";

interface AssetDownloadButtonProps {
  asset: MediaAsset;
  disabled: boolean;
}

function AssetDownloadButton({ asset, disabled }: AssetDownloadButtonProps) {
  const [downloadImage, isDownloading] = useImageDownload(asset);
  const {t} = useTranslation();

  const handleDownloadImage = (e: React.MouseEvent) => {
    e.stopPropagation();
    downloadImage();
  };

  return (
    <Tooltip title={t("assetpicker.downloadAsset")}>
      <IconButton
        disabled={disabled || isDownloading}
        size={"small"}
        onClick={handleDownloadImage}
        sx={{ color: disabled ? "primary.main" : "primary.contrastText" }}
      >
        {isDownloading ? (
          <CircularProgress size={16} />
        ) : (
          <Download fontSize={"inherit"} />
        )}
      </IconButton>
    </Tooltip>
  );
}

export default AssetDownloadButton;
