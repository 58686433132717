import {useMemo} from "react";

const useFilterArray = <T>(array: T[], query: string, filterPropsArray: (keyof T)[]): T[] => {
  return useMemo(() => {
    return array.filter((x) => {
      const arrayOfProps = filterPropsArray.map((prop) => String(x[prop]));
      return arrayOfProps.find((value) =>
        value.toLowerCase().includes(query.toLowerCase())
      );
    });
  }, [array, filterPropsArray, query]);
};

export default useFilterArray
