import { create } from "zustand";
import {Datastream, FlatDatastreamNew} from "../models/Datastream";
import axios from "axios";
import {api, makeID} from "../utils/utils";
import {FileProgressHolder} from "../components/FileManagerWidget";
import {RepoxType} from "../models/RepoxType";

async function uploadFile(
  file: File,
  data: FlatDatastreamNew,
  uuid: string,
  url: string,
  controller: AbortController,
  onUploadProgress: (progressEvent: ProgressEvent) => void
) {
  const formData = new FormData();
  formData.append("file", file);
  formData.append(
    "data",
    new Blob([JSON.stringify(data)], { type: "application/json" })
  );

  await axios
    .postForm(`${url}?to=${uuid}`, formData, { onUploadProgress, signal: controller.signal })
    .then((res) => res.data);
}


export interface FileProgress {
  [key: string]: {
    name: string,
    status: FileStatus,
    file: File
    uploadedBytes: number
    totalBytes: number
    data: any
    abortController: AbortController | null
  };
}

export interface DownloadingFile {
  id: string
  datastreamUUID: string
  inProgress: boolean
  error: boolean
  data: Datastream
}

export interface DatastreamUploadStore {
  fileProgress: FileProgress;
  uploadFiles: (
    files: File[],
    payload: any,
    uuid: string,
    url: string,
    onComplete: () => void,
    enqueueSnackbar: (message: string, config: any) => void
  ) => void;
  retryUpload: (fileProgressHolder: FileProgressHolder) => void,
  hide: boolean,
  setHide: (hide: boolean) => void,
  downloadDatastream: (datastream: Datastream) => Promise<void>,
}

export enum FileStatus {
  inProgress,
  success,
  failed,
  cancelled
}

// TODO: Update the viewURL to downloadURL
const getDownloadURL = async (datastream: Datastream) => {
  if (datastream.repoxType === RepoxType.REPOX_BITSTREAM) {
    return datastream.bitstreamFile.viewUrl
  }

  const ds = await api.get<Datastream>(`/datastreams/${datastream.objectId}`)
  return ds.data.bitstreamFile.viewUrl
}

const useDatastreamStore = create<DatastreamUploadStore>((set) => ({
  fileProgress: {},
  hide: false,
  setHide: (hide: boolean) => {
    set(prevState => ({
      ...prevState,
      hide
    }))
  },
  downloadDatastream: async (datastream: Datastream) => {

    try {

      const downloadURL = await getDownloadURL(datastream)
      window.open(downloadURL, '_blank')

    } catch (ex) {

    }
  },
  retryUpload: async (fileProgressHolder: FileProgressHolder) => {

    const { id, data, file } = fileProgressHolder

    const { payload, uuid, url } = data

    const handleState = (key: string, file: File, status: number) => {
      set(prevState => ({
        ...prevState,
        fileProgress: {
          ...prevState.fileProgress,
          [key]: {
            abortController: null,
            uploadedBytes: 0,
            totalBytes: 0,
            data: {
              payload,
              uuid
            },
            status,
            name: file.name,
            file: file,
          }
        }
      }));
    }
    handleState(id, file, FileStatus.inProgress)
    try {
      const controller = new AbortController()
      await uploadFile(file, payload, uuid, url, controller, (progressEvent => {
        set(prevState => ({
          ...prevState,
          fileProgress: {
            ...prevState.fileProgress,
            [id]: {
              ...prevState.fileProgress[id],
              abortController: controller,
              totalBytes: progressEvent.total,
              uploadedBytes: progressEvent.loaded
            }
          }
        }));
      }));
      handleState(id, file, FileStatus.success)
    } catch (ex: any) {
      handleState(id, file, FileStatus.failed)
    }
  },
  uploadFiles: async (
    files: File[],
    payload: any,
    uuid: string,
    url: string,
    onComplete: () => void,
    enqueueSnackbar: (message: string, config: any) => void
  ) => {

    const handleState = (key: string, file: File, status: number) => {
      set(prevState => ({
        ...prevState,
        fileProgress: {
          ...prevState.fileProgress,
          [key]: {
            abortController: null,
            uploadedBytes: 0,
            totalBytes: 0,
            data: {
              payload,
              uuid,
              url
            },
            status,
            name: file.name,
            file: file,
          }
        }
      }));
    }

    if (files.length === 1) {
      const key = makeID()
      handleState(key, files[0], FileStatus.inProgress)
      try {
        const controller = new AbortController()
        await uploadFile(files[0], payload, uuid, url, controller, (progressEvent => {
          set(prevState => ({
            ...prevState,
            fileProgress: {
              ...prevState.fileProgress,
              [key]: {
                ...prevState.fileProgress[key],
                abortController: controller,
                totalBytes: progressEvent.total,
                uploadedBytes: progressEvent.loaded
              }
            }
          }));
        }));
        onComplete();
        handleState(key, files[0], FileStatus.success)
      } catch (ex: any) {
        if (ex?.code === "ERR_CANCELED") {
          handleState(key, files[0], FileStatus.cancelled)
        } else {
          enqueueSnackbar(ex.response.data.message, { variant: "error" });
          handleState(key, files[0], FileStatus.failed)
        }
      }
    } else {

      const handleCompletion = (key: string, status: FileStatus) => {
        let isCompleted = false

        set(prevState => {

          const newState = {
            ...prevState,
            fileProgress: {
              ...prevState.fileProgress,
              [key]: {
                ...prevState.fileProgress[key],
                status: status
              }
            }
          }

          isCompleted = Object.keys(newState.fileProgress)
            .map(id => newState.fileProgress[id].status)
            .filter(status => status === FileStatus.inProgress).length === 0

          return newState
        })

        if (isCompleted) {
          onComplete();
        }
      }

      for (let file of files) {
        const key = makeID()
        handleState(key, file, FileStatus.inProgress)
        const data = { ...payload, label: file.name };

        const controller = new AbortController()
        uploadFile(file, data, uuid, url, controller, (progressEvent => {
          set(prevState => ({
            ...prevState,
            fileProgress: {
              ...prevState.fileProgress,
              [key]: {
                ...prevState.fileProgress[key],
                abortController: controller,
                totalBytes: progressEvent.total,
                uploadedBytes: progressEvent.loaded
              }
            }
          }));
        }))
          .then(() => {
            handleCompletion(key, FileStatus.success)
          })
          .catch(ex => {
            if (ex?.code === "ERR_CANCELED") {
              handleCompletion(key, FileStatus.cancelled)
            } else {
              enqueueSnackbar(ex.response.data.message, { variant: "error" });
              handleCompletion(key, FileStatus.failed)
            }
          })
      }
    }
  },
}));

export default useDatastreamStore;
