import StorageInfo from "models/StorageInfo";
import {bytesToSize} from "utils/utils";
import {API_URL} from "utils/config";
import {useFetch} from "hooks/common/useFetch";
import {Stack} from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import * as React from "react";
import Typography from "@mui/material/Typography";
import {useUIStore} from "../../../../store/uiStore";
import {Link} from "react-router-dom";
import {styled} from "@mui/material/styles";
import StorageIcon from '@mui/icons-material/Storage';
import Tooltip from "@mui/material/Tooltip";
import CardActionArea from "@mui/material/CardActionArea";

const StyledLink = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  textDecoration: 'none',
  transition: 'border-bottom 0.3s ease',
  borderBottom: '1px solid transparent',
  width: "max-content",
  cursor: 'pointer',
  '&:hover': {
    borderBottom: `1px solid ${theme.palette.primary.contrastText}`,
  },
}));

const btnStyles = {
  px: 1.5,
  py: 1.25,
  borderRadius: 1,
  color: "primary.contrastText",
  fontWeight: "bold",
  opacity: 0.8,
  "&:hover": { opacity: 1 }
}

function getColorByPercentage(percentage: number) {
  switch (true) {
    case percentage <= 70:
      return "#C0F2DC"
    case percentage <= 90:
      return "#FFF7AE"
    default: return "#FFBD98"
  }
}

function StorageInfoWidget() {

  const { drawerOpen } = useUIStore()
  const [storageInfo] = useFetch<StorageInfo | undefined>(`${API_URL}/storage/info`, undefined)

  if (!storageInfo) return null

  const usedSpace = storageInfo?.totalUsableSpace ?? 0;
  const maxSpace = storageInfo?.totalMaxSpace ?? 0;

  // Calculate the percentage used
  const percentageUsed = (usedSpace / maxSpace) * 100;

  const largeLabel = `${bytesToSize(usedSpace)} of ${bytesToSize(maxSpace)} used`;

  if (!drawerOpen) {
    return  (
      <Link to={"/store/storage"} style={{ textDecoration: "none", maxWidth: "100%", marginRight: "auto" }}>
        <CardActionArea sx={{ ...btnStyles }}>
          <Stack justifyContent={"center"} direction={"row"}>
            <Tooltip title={largeLabel} placement={"right"}>
              <StorageIcon sx={{ color: "primary.contrastText" }}/>
            </Tooltip>
          </Stack>
        </CardActionArea>
      </Link>
    )
  }

  const color = getColorByPercentage(percentageUsed)

  return (
    <Stack rowGap={0.5} flex={1}>
      <Link
        to={"/store/storage"}
        style={{ textDecoration: "none", maxWidth: "100%" }}
      >
        <StyledLink variant={"body2"} color={"primary.contrastText"}>
          {largeLabel}
        </StyledLink>
      </Link>
      <Stack sx={{ color }}>
        <LinearProgress
          variant="determinate"
          value={percentageUsed}
          color="inherit"
          sx={{ height: 6, borderRadius: 1 }}
        />
      </Stack>
    </Stack>
  );
}

export default StorageInfoWidget
