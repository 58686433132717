import {createMap} from "../../../utils/utils";

export interface Language {
  code: string;
  name: string;
  flagCode: string;
}

export const languages : Language[] = [
  { "code": "el", "name": "guides.settings.languages.gr", flagCode: "gr" },
  { "code": "en", "name": "guides.settings.languages.gb", flagCode: "gb" },
  { "code": "de", "name": "guides.settings.languages.de", flagCode: "de" },
  { "code": "es", "name": "guides.settings.languages.es", flagCode: "es" },
  { "code": "it", "name": "guides.settings.languages.it", flagCode: "it" },
  { "code": "fr", "name": "guides.settings.languages.fr", flagCode: "fr" },
  { "code": "tr", "name": "guides.settings.languages.tr", flagCode: "tr" }
]

export const languageMap = createMap(languages, "code")
