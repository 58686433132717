import { Dialog as MuiDialog } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import * as React from "react";
import { TransitionProps } from "@mui/material/transitions";
import Slide from "@mui/material/Slide";
import CloseIcon from "@mui/icons-material/Close";
import { Datastream } from "../../models/Datastream";
import { useMap } from "../../hooks/common/useMap";
import {
  Fragment,
  MutableRefObject,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useFetch } from "../../hooks/common/useFetch";
import { API_URL } from "../../utils/config";
import { useHasPreview } from "../../hooks/datastream/useHasPreview";
import { useNavigate } from "react-router-dom";
import NoPreviewAvailable from "../Common/NoPreviewAvailable";
import { lazy, Suspense } from "react";
import useImagePreviewActions from "../../hooks/datastream/useImagePreviewActions";
import { getToken } from "../../utils/utils";
import Button from "@mui/material/Button";

const DatastreamPreview = lazy(() => import("./DatastreamPreview"));

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

type DialogProps = {
  open: boolean;
  handleClose: () => void;
  previewUuid: string | null;
  isShortcut: boolean;
  shortcuts: Datastream[];
  toNavigate: boolean;
  token?: string;
};

function PreviewDialog({
  open,
  handleClose,
  previewUuid,
  isShortcut,
  shortcuts,
  toNavigate,
  token,
}: DialogProps) {
  const navigate = useNavigate();

  const shortcutMap = useMap(shortcuts, "uuid");

  const uuid = useMemo(() => {
    if (!open && !previewUuid) return "";
    if (isShortcut) {
      const shortcut = shortcutMap.get(previewUuid) ?? new Datastream();
      return shortcut.objectId;
    }
    return previewUuid;
  }, [previewUuid, isShortcut, shortcutMap, open]);

  const [data, isLoading] = useFetch<Datastream>(
    uuid
      ? token
        ? `${API_URL}/datastreams/${uuid}?tk=${token}`
        : `${API_URL}/datastreams/${uuid}`
      : null,
    new Datastream()
  );

  const { hasPreview, deleted } = useHasPreview(
    data.bitstreamFile?.mimeType,
    data.bitstreamFile?.filename
  );

  const { editAnnotations, toolbar } = useImagePreviewActions(data);

  const [startLoading, setStartLoading] = useState(false);

  // https://repox-store-collabora-development.dev.datawise.ai:443/collabora/browser/fc0de5b/cool.html?WOPISrc=https://repox-store-api-development.dev.datawise.ai/wopi/files/559ddb60-f610-4e75-bc16-0502bd21a892

  const formRef = useRef() as MutableRefObject<any>;


  const renderContent = () => {
    if (data.bitstreamFile.externalEditorUrl) {
      return (
        <iframe
          title="Collabora Online Viewer"
          id="collabora-online-viewer"
          name="collabora-online-viewer"
          width="100%"
          height="100%"
        />
      );
    }

    if (hasPreview) {
      return (
        <Suspense
          fallback={
            <Stack
              width={"100%"}
              alignItems={"center"}
              justifyContent={"center"}
              height={"100%"}
            >
              <CircularProgress />
            </Stack>
          }
        >
          <DatastreamPreview
            fileType={data?.bitstreamFile?.mimeType ?? ""}
            viewUrl={data.bitstreamFile?.viewUrl}
            filename={data?.bitstreamFile?.filename ?? ""}
            datastreamUUID={data.uuid}
            editAnnotations={editAnnotations}
          />
        </Suspense>
      );
    }

    return (
      <Stack sx={{ mt: 2 }} alignItems={"center"}>
        {deleted ? (
          <Typography variant={"caption"}>
            The original datastream has been deleted
          </Typography>
        ) : (
          <NoPreviewAvailable />
        )}
      </Stack>
    );
  };

  const timeoutRef = useRef() as MutableRefObject<any>

  useEffect(() => {
    if (!open || !data.bitstreamFile.externalEditorUrl) return
    clearTimeout(timeoutRef.current)
    setStartLoading(true)
    timeoutRef.current = setTimeout(() => {
      formRef.current.submit();
      setStartLoading(false)
    }, 500)
  }, [open, data.bitstreamFile.externalEditorUrl]);

  return (
    <Fragment>
      <MuiDialog
        open={open}
        onClose={handleClose}
        fullScreen
        TransitionComponent={Transition}
        PaperProps={{
          style: {
            backgroundColor: "black",
            color: "white",
          },
        }}
      >
        {isLoading && <LinearProgress style={{ marginBottom: -4 }} />}

        <DialogTitle id="alert-dialog-title">
          {isLoading ? (
            <Stack
              flexDirection={"row"}
              alignItems={"center"}
              justifyContent={"flex-start"}
              gap={1}
            >
              <Typography variant={"caption"}>loading datastream...</Typography>
            </Stack>
          ) : (
            <Stack
              flexDirection={"row"}
              alignItems={"center"}
              justifyContent={"flex-start"}
              gap={1}
            >
              <Typography variant={"caption"}>
                {data.bitstreamFile?.filename}
              </Typography>
              <div style={{ flexGrow: 1 }}></div>
              {!deleted && toolbar()}
              <IconButton
                size={"small"}
                aria-label="close"
                onClick={
                  !toNavigate
                    ? handleClose
                    : () => navigate(`/store/content/${data.parent}`)
                }
                sx={{
                  color: "common.white",
                }}
              >
                <CloseIcon fontSize={"small"} />
              </IconButton>
            </Stack>
          )}
        </DialogTitle>
        <DialogContent sx={{ m: 0, p: 0 }}>
          <Stack sx={{maxHeight: "100%", height: "100%"}}>
            {renderContent()}
            <div style={{ display: "none" }}>
              <form
                ref={formRef}
                action={data.bitstreamFile.externalEditorUrl}
                encType="multipart/form-data"
                method="post"
                target="collabora-online-viewer"
                id="collabora-submit-form"
              >
                <input
                  name="access_token"
                  value={getToken()}
                  type="hidden"
                  id="access-token"
                />
                <Button type={"submit"}>Submit</Button>
              </form>
            </div>
          </Stack>
        </DialogContent>
      </MuiDialog>
    </Fragment>
  );
}

export default PreviewDialog;
