import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import React, { useCallback, useMemo } from "react";
import ShareLink from "../../models/ShareLink";
import { Datastream } from "../../models/Datastream";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import { StyledBreadcrumb } from "../../components/Common/BreadCrumb";
import { useLocation, useNavigate } from "react-router-dom";
import useQuery, {
  updateQueryStringParameter,
} from "../../hooks/query/useQuery";
import PreviewDialog from "../../components/PreviewDialog";
import DatastreamCardBox from "../../components/DatastreamCardBox";

type DatastreamViewProps = {
  datastream: Datastream;
  link: ShareLink;
};

const DatastreamView = ({ datastream, link }: DatastreamViewProps) => {
  const { pathname, search } = useLocation();
  const searchParams = useQuery();
  const navigate = useNavigate();

  const previewUuid = searchParams.get("datastream");
  const isShortcut = Boolean(searchParams.get("isShortcut"));

  const handlePreview = useCallback(() => {
    const newSearch = updateQueryStringParameter(
      search,
      "datastream",
      datastream.uuid
    );
    navigate(`${pathname}${newSearch}`);
  }, [datastream, navigate, pathname, search]);

  const openPreview = useMemo(() => {
    return !!previewUuid;
  }, [previewUuid]);

  return (
    <Container maxWidth={"sm"} sx={{ pb: 10 }}>
      <Card sx={{ border: "none", mt: 4, boxShadow: 12 }}>
        <CardContent>
          <Stack
            direction={"column"}
            rowGap={1}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Stack direction={"row"} alignItems={"center"}>
              <StyledBreadcrumb label={`${datastream.label}`} />
              <div style={{ flexGrow: 1 }}></div>
            </Stack>
            {link.expiredAt && (
              <Stack flexDirection={"row"} alignItems={"center"} columnGap={1}>
                <PriorityHighIcon color={"primary"} fontSize={"small"} />
                <Typography variant={"caption"} color={"primary"}>
                  {" "}
                  link expires at: {link.expiredAt.split(" ")[0]}
                </Typography>
              </Stack>
            )}
          </Stack>

          <Stack
            flexDirection={"column"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Box sx={{ mt: 2 }}>
              <DatastreamCardBox
                datastream={datastream}
                onDoubleClick={handlePreview}
              />
            </Box>
          </Stack>
        </CardContent>
      </Card>
      <PreviewDialog
        open={openPreview}
        handleClose={() => {
          navigate(`${pathname}`);
        }}
        previewUuid={previewUuid}
        isShortcut={isShortcut}
        shortcuts={[datastream]}
        toNavigate={false}
        token={link.token}
      />
    </Container>
  );
};

export default DatastreamView;
