import React from "react";
import ProfileActionType from "../../../../models/ProfileActionType";
import PermIdentityOutlinedIcon from "@mui/icons-material/PermIdentityOutlined";
import { Logout } from "@mui/icons-material";
import NewReleasesOutlinedIcon from "@mui/icons-material/NewReleasesOutlined";
import DeveloperBoardOutlinedIcon from "@mui/icons-material/DeveloperBoardOutlined";
import IAMPermission from "../../../../models/IAMPermission";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import FeedbackOutlinedIcon from "@mui/icons-material/FeedbackOutlined";

export const actions = [
  {
    type: ProfileActionType.account,
    name: "toolbar.account.myAccount",
    Icon: PermIdentityOutlinedIcon,
    disabled: false,
    path: "/store/manage-account"
  },
  {
    name: "toolbar.account.adminConsole",
    type: ProfileActionType.console,
    Icon: DeveloperBoardOutlinedIcon,
    permissions: [IAMPermission.developer_console],
    path: "/store/control-panel"
  },
  {
    name: "toolbar.account.releaseNotes",
    type: ProfileActionType.releases,
    Icon: NewReleasesOutlinedIcon,
    path: "/store/release-notes"
  },
  {
    name: "toolbar.account.documentation",
    type: ProfileActionType.documentation,
    disabled: false,
    Icon: HelpOutlineIcon,
    path: "https://docs.repox.io"
  },
  {
    divider: true,
  },
  {
    type: ProfileActionType.feedback,
    name: "toolbar.account.feedback",
    Icon: FeedbackOutlinedIcon,
    disabled: false,
  },
  {
    name: "toolbar.account.logOut",
    type: ProfileActionType.logout,
    disabled: false,
    Icon: () => <Logout fontSize="small" sx={{ color: "error.main" }} />,
    textColor: "error",
  },
];
