export enum JobType {
  IDX_FULLTEXT_DATASTREAM_CREATE = "IDX_FULLTEXT_DATASTREAM_CREATE",
  IDX_FULLTEXT_DATASTREAM_UPDATE = "IDX_FULLTEXT_DATASTREAM_UPDATE",
  IDX_FULLTEXT_REINDEX_ALL = "IDX_FULLTEXT_REINDEX_ALL",
  IDX_FULLTEXT_REINDEX_MISSING = "IDX_FULLTEXT_REINDEX_MISSING",
  IDX_AI_DATASTREAM_CREATE = "IDX_AI_DATASTREAM_CREATE",
  IDX_AI_DATASTREAM_UPDATE = "IDX_AI_DATASTREAM_UPDATE",
  IDX_AI_REINDEX_ALL = "IDX_AI_REINDEX_ALL",
  IDX_AI_REINDEX_MISSING = "IDX_AI_REINDEX_MISSING",
  IDX_AI_DELETE_BY_DATASTREAM = "IDX_AI_DELETE_BY_DATASTREAM",
  FILE_INTEGRITY = "FILE_INTEGRITY",
  DATASTREAM_THUMBNAIL_CREATE = "DATASTREAM_THUMBNAIL_CREATE",
  PREVIEW_XLSX = "PREVIEW_XLSX",
  IMPORT_XLSX = "IMPORT_XLSX",
  IMPORT_XLSX_REVALIDATE = "IMPORT_XLSX_REVALIDATE",
  IMPORT_ARCHIVE = "IMPORT_ARCHIVE",
  EXPORT_XLSX = "EXPORT_XLSX",
  EXPORT_ARCHIVE = "EXPORT_ARCHIVE",
  GUIDE_BUILD = "GUIDE_BUILD",
  REMOVE = "REMOVE",
  RESTORE = "RESTORE",
  DELETE = "DELETE",
}
