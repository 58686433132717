import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import React from "react";
import { Link } from "react-router-dom";
import FilterList from "./FilterList";
import Container from "../../models/Container";
import {FullTextSearchDatastream} from "../../models/Datastream";
import ContainerCover from "../ContainerCover";
import DatastreamCover from "../DatastreamCover";
import moment from "moment";

type GlobalSearchPopperProps = {
  value: string;
  containers: Container[];
  datastreams: FullTextSearchDatastream[];
  handleClose: () => void;
  onFilterClick: (parameters: string) => void;
};

export const GlobalSearchPopper = ({
  value,
  containers,
  datastreams,
  handleClose,
  onFilterClick,
}: GlobalSearchPopperProps) => {
  return (
    <Paper
      sx={{
        width: "500px",
        height: "auto",
        overflow: "hidden",
        mt: 1
      }}
      elevation={6}
    >
      {value ? (
        <MenuList
          sx={{
            maxHeight: "300px",
          }}
        >
          {containers.map((item) => (
            <Link
              key={item.uuid}
              to={`/store/content/${item.uuid}`}
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <MenuItem id={item.uuid} onClick={handleClose} >
                <Stack
                  flexDirection={"row"}
                  columnGap={2}
                  alignItems={"center"}
                  flexGrow={1}
                >
                  <ContainerCover size={36} coverFile={item.coverFile} />
                  <Stack
                    flexDirection={"column"}
                  >
                    <Typography noWrap variant={"caption"}>
                      {item.label}
                    </Typography>
                    {item.updatedAt && item.updatedBy && (
                      <Typography noWrap color={"textSecondary"} variant={"body2"}>
                        {`${moment(item.updatedAt).fromNow()} from ${item.updatedBy.firstname} ${
                          item.updatedBy.lastname
                        }`}
                      </Typography>
                    )}
                  </Stack>
                </Stack>
              </MenuItem>
            </Link>
          ))}
          {datastreams.map((item) => (
            <Link
              key={item.uuid}
              to={`/store/content/${item.parent}`}
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <MenuItem id={item.uuid} onClick={handleClose}>
                <Stack
                  flexDirection={"row"}
                  columnGap={2}
                  alignItems={"center"}
                  flexGrow={1}
                >
                  <DatastreamCover size={36} datastream={item} />
                  <Stack
                    flexDirection={"column"}
                  >
                    <Typography noWrap variant={"caption"}>
                      {item.label}
                    </Typography>
                    {item.updatedAt && item.updatedBy && (
                      <Typography noWrap color={"textSecondary"} variant={"body2"}>
                        {`${moment(item.updatedAt).fromNow()} from ${item.updatedBy.firstname} ${
                          item.updatedBy.lastname
                        }`}
                      </Typography>
                    )}
                  </Stack>
                </Stack>
              </MenuItem>
            </Link>
          ))}
          {!datastreams.length && !containers.length && (
            <MenuItem disabled={true}>
              <ListItemText sx={{ textAlign: "center" }}>
                <Typography variant={"body1"}>No results</Typography>
              </ListItemText>
            </MenuItem>
          )}
        </MenuList>
      ) : (
        <FilterList onFilterClick={onFilterClick} handleClose={handleClose} />
      )}
    </Paper>
  );
};
