import GuideApplication from "../extensions/guide/models/GuideApplication";
import { create } from "zustand";

type GuideStoreType = {
  guide: GuideApplication;
  getGuide: (id: string, selectedLanguage?: string) => Promise<void>;
  setGuide: (values: any) => void;
  selectedLanguage: string;
  setSelectedLanguage: (lang: string) => void;
};

export const useGuideStore = create<GuideStoreType>((set) => ({
  guide: new GuideApplication(),
  getGuide: async (id: string, selectedLanguage?: string) => {
    try {
      if (selectedLanguage) {
        const guide = await GuideApplication.getByIDAndLanguage(id, selectedLanguage)
        set({ guide })
      } else {
        const guide = await GuideApplication.getByID(id)
        set({ guide, selectedLanguage: guide.defaultLanguage })
      }
    } catch (ex) {
    }
  },
  setSelectedLanguage: (selectedLanguage: string) => {
    set({ selectedLanguage })
  },
  selectedLanguage: "",
  setGuide: (values: any) => {
    set({ guide: values })
  },
}));
