import React, {Fragment, useEffect, useMemo, useState} from "react";
import Dialog from "components/@lib/Dialog";
import { Stack } from "@mui/material";
import {useMap} from "hooks/common/useMap";
import useFilterArray from "hooks/common/useFilterArray";
import TableSelectorButton from "components/@lib/Inputs/Common/TableSelectorButton";
import VirtualizedTable from "../../VirtualizedTable";

interface Option {
  id: string;
  name: string;
}

type Props = {
  label: string;
  required?: boolean;
  recommended?: boolean;
  helperText?: string;
  error?: boolean;
  name: string;
  value?: string | string[];
  onChange: (selected: string[]) => void;
  multiSelect?: boolean;
  disabled?: boolean;
  options: Option[]
  headCells?: HeadCell[]
};

type HeadCell = {
  id: string
  label: string
}


function TableSelect(props: Props) {
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState("")

  const {
    label,
    required,
    recommended,
    onChange,
    error,
    helperText,
    value,
    disabled,
    multiSelect,
    options,
  } = props;

  const filtered = useFilterArray(options, search, ["name"])

  const [selected, setSelected] = useState<string[]>([])


  useEffect(() => {
    if (open) {
      if (Array.isArray(value)) {
        setSelected(value)
      } else if (typeof value == "string"){
        setSelected([value])
      }
    }
  }, [open, setSelected, value]);

  const handleConfirmSelection = () => {
    setOpen(false);
    onChange(selected);
  };

  const optionMap = useMap(options, "id")

  const valueString = useMemo(() => {
    if (Array.isArray(value)) {
      if (value.length === 0) return undefined
      return value.map(id => optionMap.get(id)?.name ?? "").join(", ")
    } else {
      if (!value) return undefined
      return optionMap.get(value)?.name
    }
  }, [optionMap, value])

  return (
    <Fragment>
      <TableSelectorButton
        handleClick={() => setOpen(true)}
        label={label}
        required={required}
        recommended={recommended}
        disabled={disabled}
        valueString={valueString ?? "None"}
        error={error}
        helperText={helperText}
      />

      <Dialog
        primaryAction={handleConfirmSelection}
        maxWidth={"sm"}
        fullWidth
        open={open}
        handleClose={() => setOpen(false)}
        title={label}
        searchable={[search, setSearch]}
      >
        <Stack rowGap={2}>
          <VirtualizedTable
            multiSelect={multiSelect}
            selected={selected}
            setSelected={setSelected}
            rows={filtered}
          />
        </Stack>
      </Dialog>
    </Fragment>
  );
}

export default TableSelect;
