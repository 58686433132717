import {useEffect, useState} from "react";
import {useSnackbar} from "notistack";
import axios from "axios";

const useQRCode = (url: string, open: boolean) => {

  const [qrCodeData, setQrCodeData] = useState("");
  const [inProgress, setInProgress] = useState(false);

  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    if (!open || qrCodeData) return;

    setInProgress(true);

    axios
      .post(url, { size: 200 }, { responseType: "blob" })
      .then((response) => {
        const imageUrl = URL.createObjectURL(response.data);
        setQrCodeData(imageUrl);
      })
      .catch((error) => {
        enqueueSnackbar(error.message, { variant: "error" })
      })
      .finally(() => setInProgress(false));
  }, [url, enqueueSnackbar, open, qrCodeData]);

  return {
    inProgress,
    qrCodeData
  }
}

export default useQRCode