enum ContextActionType {
  edit,
  info,
  star,
  color,
  rename,
  import,
  export,
  move,
  shortcut,
  watch,
  unwatch,
  report,
  print,
  share,
  search,
  delete,
  location,
  publish,
  unPublish,
  default,
  viewUsage,
  trashRestore,
  trashDelete,
  activity,
  events,
  enable,
  disable,
  xrApplicationPublish,
  xrApplicationUnPublish,
  xrApplicationExport,
  xrAnchorView,
  create,
  duplicate,
  uploadFile,
  editPermissions,
  downloadSample,
  init,
  rebuild,
  ref,
  organize,
  details,
  view,
  download,
  editText,
  replaceFile,
  replaceThumb,
  setCover,
  fileInfo,
  clone,
  merge
}

export default ContextActionType;
