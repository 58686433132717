import { SxProps } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import NativeSelect from "@mui/material/NativeSelect";
import Stack from "@mui/material/Stack";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import React, { ChangeEventHandler } from "react";
import MenuItem from "@mui/material/MenuItem";
import { Option } from "../../../hooks/form/useFormElements";
import { useMap } from "../../../hooks/common/useMap";
import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";
import useMediaQuery from "@mui/material/useMediaQuery";
import Popper from "@mui/material/Popper";
import { styled, useTheme } from "@mui/material/styles";
import { ListChildComponentProps, VariableSizeList } from "react-window";
import Typography from "@mui/material/Typography";
import { AddCircle } from "@mui/icons-material";
import FormHelperText from "@mui/material/FormHelperText";
import { useTranslation } from "react-i18next";

type SelectorProps = {
  label?: string;
  options: any;
  value: string | number | boolean;
  onChange?: ChangeEventHandler<HTMLSelectElement>;
  name: string;
  fullWidth?: boolean;
  disabled?: boolean;
  hasNone?: boolean;
  size?: "small" | "medium" | undefined;
  required?: boolean;
  recommended?: boolean;
  helperText?: string;
  error?: boolean;
  sx?: SxProps;
  variant?: "standard" | "filled" | "outlined"
  createOption?: () => void
};

function Selector({
  label,
  options,
  value,
  onChange,
  name,
  disabled,
  fullWidth,
  hasNone,
  size,
  required,
  createOption
}: SelectorProps) {
  const {t} = useTranslation();

  const renderOption = (option: any, index: number) => {

    if (option.groupName) {
      return (
        <optgroup label={option.groupName}>
          {option.options.map(renderOption)}
        </optgroup>
      );
    }

    return (
      <option key={`${option.id}_${index}`} value={option.id} disabled={option.disabled}>
        {option.name}
      </option>
    );
  };

  return (
    <Stack rowGap={1} sx={{ flex: 1 }}>
      <FormControl required={required} size={"small"} fullWidth={fullWidth}>
        {label && (
          <InputLabel shrink variant="standard" htmlFor="uncontrolled-native">
            {label}
          </InputLabel>
        )}
        <NativeSelect
          disabled={disabled}
          value={value}
          onChange={onChange}
          size={size}
          required={required}
          sx={{ minWidth: 100 }}
          inputProps={{
            name: name,
            id: name,
          }}
        >
          {hasNone && (
            <option value={""}>
              <em>{t("options.none")}</em>
            </option>
          )}
          {options.map(renderOption)}
        </NativeSelect>
      </FormControl>
      {createOption && (
        <Button
          startIcon={<AddCircle />}
          sx={{ alignSelf: "flex-start" }}
          size={"small"}
          onClick={createOption}
        >
          Add new
        </Button>
      )}
    </Stack>
  );
}

export default Selector;

export function MuiSelector({
  label,
  options,
  value,
  onChange,
  name,
  disabled,
  fullWidth,
  hasNone,
  size,
  required,
  recommended,
  error,
  helperText,
  sx,
  variant = "standard"
}: SelectorProps) {
  const {t} = useTranslation();
  return (
    <FormControl size={"small"} variant={variant} fullWidth={fullWidth}>
      <InputLabel id={`${name}-select-label`}>
        {label} {required && "*"} {recommended && <sup>(Rec)</sup>}
      </InputLabel>
      <Select
        labelId={`${name}-select-label`}
        id={`${name}-select`}
        label={label}
        disabled={disabled}
        name={name}
        // @ts-ignore
        onChange={onChange}
        value={value ?? ""}
        size={size}
        sx={{ ...sx }}
        required={required}
        error={error}
        // helperText={helperText}
        MenuProps={{ PaperProps: { sx: { maxHeight: 350 } } }}
      >
        {hasNone && (
          <MenuItem value="">
            <em>{t("options.none")}</em>
          </MenuItem>
        )}
        {options.map((opt: any) => (
          <MenuItem key={opt.id} value={opt.id}>
            <Stack direction={"row"} alignItems={"center"}>
              {opt.color && <Box borderRadius={0.5} width={16} height={16} bgcolor={opt.color} mr={1}/>}
              {opt.name}
            </Stack>
          </MenuItem>
        ))}
      </Select>
      {helperText && <FormHelperText error={error}>{helperText}</FormHelperText>}
    </FormControl>
  );
}

type MultiSelectorProps = {
  label?: string;
  options: Option[];
  value: string[];
  onChange: ChangeEventHandler<HTMLSelectElement>;
  name: string;
  fullWidth?: boolean;
  disabled?: boolean;
  required?: boolean;
  recommended?: boolean;
  helperText?: string;
  error?: boolean;
  sx?: SxProps;
  createOption?: false | (() => void);
};

const LISTBOX_PADDING = 8; // px

function renderRow(props: ListChildComponentProps) {
  const { data, index, style } = props;
  const dataSet = data[index];
  const inlineStyle = {
    ...style,
    top: (style.top as number) + LISTBOX_PADDING,
  };

  return (
    <Typography component="li" {...dataSet[0]} noWrap style={inlineStyle}>
      {dataSet[0].key}
    </Typography>
  );
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data: any) {
  const ref = React.useRef<VariableSizeList>(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

// Adapter for react-window
const ListboxComponent = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLElement>
>(function ListboxComponent(props, ref) {
  const { children, ...other } = props;
  const itemData: React.ReactChild[] = [];
  (children as React.ReactChild[]).forEach(
    (item: React.ReactChild & { children?: React.ReactChild[] }) => {
      itemData.push(item);
      itemData.push(...(item.children || []));
    }
  );

  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"), {
    noSsr: true,
  });
  const itemCount = itemData.length;
  const itemSize = smUp ? 36 : 48;

  const getChildSize = (child: React.ReactChild) => {
    if (child.hasOwnProperty("group")) {
      return 48;
    }

    return itemSize;
  };

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
  };

  const gridRef = useResetCache(itemCount);

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={(index) => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: "border-box",
    "& ul": {
      padding: 0,
      margin: 0,
    },
  },
});

export function MultiSelector({
  createOption,
  label,
  options,
  value,
  onChange,
  name,
  disabled,
  fullWidth,
  required,
  recommended,
  error,
  helperText,
  sx,
}: MultiSelectorProps) {
  const { t } = useTranslation();
  const optionMap = useMap(options);

  const selectedValues = value ?
    value.map((id) => {
    const option = optionMap.get(id);
    return option || { id, name: id } }) : [];

  return (
    <Stack rowGap={1} sx={{ flex: 1 }}>
      <Autocomplete
        id={name}
        fullWidth={fullWidth}
        multiple
        value={selectedValues}
        onChange={(event, newValue) => {
          onChange({
            target: {
              // @ts-ignore
              value: newValue.map((x) => x.id),
              name: name,
            },
          });
        }}
        disabled={disabled}
        sx={{ ...sx }}
        size={"medium"}
        disableListWrap
        PopperComponent={StyledPopper}
        ListboxComponent={ListboxComponent}
        options={options}
        getOptionLabel={(option) => option.name}
        renderInput={(params) => (
          <TextField
            {...params}
            name={name}
            variant="standard"
            label={<>{label} {(required && selectedValues.length > 0) && "*"} {recommended && <sup>(Rec)</sup>}</>}
            required={required && selectedValues.length === 0}
            error={error}
            helperText={helperText}
          />
        )}
        renderOption={(props, option, state) =>
          [props, option, state] as React.ReactNode
        }
      />
      {createOption && (
        <Button startIcon={<AddCircle/>} sx={{ alignSelf: "flex-start" }} size={"small"} onClick={createOption}>
          {t("createOption")}
        </Button>
      )}
    </Stack>
  );
}

type SingleSelectorProps = {
  label?: string;
  options: Option[];
  value: Option | null;
  onChange: React.Dispatch<React.SetStateAction<Option | null>>;
  name: string;
  sx?: SxProps;
  helperText?: string
  error?: boolean;
};

export function SingleSelector({
  label,
  options,
  value,
  onChange,
  name,
  sx,
  helperText,
  error
}: SingleSelectorProps) {
  return (
    <Stack rowGap={1}>
      <Autocomplete
        id={name}
        value={value}
        onChange={(event, newValue) => {
         onChange(newValue);
        }}
        sx={{ ...sx }}
        size={"medium"}
        disableListWrap
        PopperComponent={StyledPopper}
        ListboxComponent={ListboxComponent}
        options={options}
        getOptionLabel={(option) => option.name}
        renderInput={(params) => (
          <TextField
            {...params}
            name={name}
            variant="standard"
            label={label}
            required={true}
            helperText={helperText}
            error={error}
          />
        )}
        renderOption={(props, option, state) =>
          [props, option, state] as React.ReactNode
        }
      />
    </Stack>
  );
}

type SingleVocSelectorProps = {
  label?: string;
  options: Option[];
  value: Option | null;
  onChange: React.Dispatch<React.SetStateAction<Option | null>>;
  name: string;
  helperText?: string;
  error?: boolean;
  disablePortal?: boolean;
  disabled?: boolean;
};

export function SingleVocSelector({
  label,
  options,
  value,
  onChange,
  name,
  helperText,
  error,
  disablePortal = false,
  disabled
}: SingleVocSelectorProps) {
  return (
    <Autocomplete
      id={name}
      value={value}
      onChange={(event, newValue) => {
        onChange(newValue ? newValue.id : "");
      }}
      sx={{ flex: 1 }}
      size={"small"}
      disablePortal={disablePortal}
      disableListWrap
      PopperComponent={StyledPopper}
      ListboxComponent={ListboxComponent}
      options={options}
      disabled={disabled}
      getOptionLabel={(option) => option.name}
      renderInput={(params) => (
        <TextField
          {...params}
          name={name}
          variant="outlined"
          label={label}
          required={false}
          helperText={helperText}
          error={error}
        />
      )}
      renderOption={(props, option, state) =>
        [props, option, state] as React.ReactNode
      }
    />
  );
}
