import PropTypes from "prop-types";
import { useMemo } from "react";
// material
import { CssBaseline } from "@mui/material";
import {
  ThemeProvider as MUIThemeProvider,
  createTheme,
  StyledEngineProvider,
} from "@mui/material/styles";
//
import classic from "./palettes/classic";
import dark from "./palettes/dark";
import typography from "./typography";
import chip from "./chip";
import componentsOverride from "./overrides";
import shadows, { customShadows } from "./shadows";
import usePaletteStore, {Palette} from "../store/useThemeConfiguration";
import useMediaQuery from "@mui/material/useMediaQuery";

// ----------------------------------------------------------------------

ThemeProvider.propTypes = {
  children: PropTypes.any,
};

const getPaletteFromSelection = (palette, prefersDark) =>  {
  switch (palette) {
    case Palette.classic: return classic
    case Palette.dark: return dark
    case Palette.os: return prefersDark ? dark : classic
    default: return classic
  }
}


export default function ThemeProvider({ children }) {

  const { palette } = usePaletteStore()
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  const themeOptions = useMemo(() =>  {
      const pl = getPaletteFromSelection(palette, prefersDarkMode)
      return {
        palette: pl,
        shape: { borderRadius: 8 },
        typography,
        chip,
        shadows: shadows(pl.grey[500]),
        customShadows: customShadows(pl.grey[500], pl),
      }
    }, [palette, prefersDarkMode]);

  const theme = createTheme(themeOptions);
  theme.components = componentsOverride(theme);

  return (
    <StyledEngineProvider injectFirst>
      <MUIThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </MUIThemeProvider>
    </StyledEngineProvider>
  );
}
