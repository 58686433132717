import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { TableVirtuoso, TableComponents } from "react-virtuoso";
import { Dispatch, SetStateAction } from "react";
import { Checkbox, Radio } from "@mui/material";
import Typography from "@mui/material/Typography";
import TableLoader from "../../DefaultTable/table-loader";

interface Data {
  id: string;
  name: string;
}

interface ColumnData {
  dataKey: keyof Data;
  label: string;
  numeric?: boolean;
  width: number;
}

const columns: ColumnData[] = [
  {
    width: 200,
    label: "Label",
    dataKey: "name",
  },
];

const VirtuosoTableComponents: TableComponents<Data> = {
  Scroller: React.forwardRef<HTMLDivElement>((props, ref) => (
    <TableContainer component={Paper} {...props} ref={ref} sx={{ backgroundColor: "background.neutral" }}/>
  )),
  Table: (props) => (
    <Table
      {...props}
      sx={{
        borderCollapse: "separate",
        tableLayout: "fixed",
        backgroundColor: "background.neutral",
      }}
      className={"table-gap"}
      style={{
        borderCollapse: "separate",
        borderSpacing: "0px 8px",
      }}
    />
  ),
  TableHead,
  TableRow: ({ item: _item, ...props }) => (
    <TableRow {...props} sx={{ backgroundColor: "background.paper" }} hover />
  ),
  TableBody: React.forwardRef<HTMLTableSectionElement>((props, ref) => (
    <TableBody {...props} ref={ref} />
  )),
};

function fixedHeaderContent() {
  return (
    <TableRow>
      <TableCell
        variant="head"
        style={{ width: 0 }}
        sx={{
          backgroundColor: "background.neutral",
          border: 0,
        }}
      />
      {columns.map((column) => (
        <TableCell
          key={column.dataKey}
          variant="head"
          align={column.numeric || false ? "right" : "left"}
          style={{ width: "100%" }}
          sx={{
            py: 0,
            backgroundColor: "background.neutral",
            border: 0,
          }}
        >
          <Typography noWrap sx={{ fontWeight: "bold" }} variant={"body2"}>
            {column.label}
          </Typography>
        </TableCell>
      ))}
    </TableRow>
  );
}

type onClick = (id: string) => void;

function rowContent(
  _index: number,
  row: Data,
  handleClick: onClick,
  isItemSelected: (id: string) => boolean,
  multiSelect?: boolean
) {
  return (
    <React.Fragment>
      <TableCell padding="checkbox" onClick={() => handleClick(row.id)}>
        {multiSelect ? (
          <Checkbox
            checked={isItemSelected(row.id)}
            inputProps={{ "aria-labelledby": row.id }}
          />
        ) : (
          <Radio
            checked={isItemSelected(row.id)}
            inputProps={{ "aria-labelledby": row.id }}
          />
        )}
      </TableCell>
      {columns.map((column) => (
        <TableCell
          onClick={() => handleClick(row.id)}
          key={column.dataKey}
          align={column.numeric || false ? "right" : "left"}
        >
          {row[column.dataKey]}
        </TableCell>
      ))}
    </React.Fragment>
  );
}

interface Props {
  selected: string[];
  setSelected: Dispatch<SetStateAction<string[]>>;
  multiSelect?: boolean;
  rows: Data[];
  loading?: boolean
}

export default function VirtualizedTable(props: Props) {
  const { loading, rows, multiSelect, setSelected, selected } = props;

  const handleClick = (id: string) => {
    setSelected((prevState) => {
      const exist = prevState.includes(id);
      if (!multiSelect) {
        return exist ? [] : [id];
      }

      if (exist) return prevState.filter((x) => x !== id);
      return [...prevState, id];
    });
  };

  const isItemSelected = (id: string) => selected.includes(id);

  if (loading) {
    return <TableLoader rounded />
  }

  return (
    <Paper
      style={{ height: 448, width: "100%" }}
      sx={{
        backgroundColor: "background.neutral",
        borderRadius: 0.5,
        px: 1,
        pb: 1,
      }}
    >
      <TableVirtuoso
        data={rows}
        components={VirtuosoTableComponents}
        fixedHeaderContent={fixedHeaderContent}
        itemContent={(index, data) =>
          rowContent(index, data, handleClick, isItemSelected, multiSelect)
        }
      />
    </Paper>
  );
}
