import * as React from "react";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuList from "@mui/material/MenuList";
import { actions } from "./ProfileActions";
import ProfileActionType from "../../../../models/ProfileActionType";
import { useSnackbar } from "notistack";
import {Link, useNavigate} from "react-router-dom";
import axios from "axios";
import { getTenantId } from "../../../../utils/utils";
import { useIAMPermission, useProfileStore } from "../../../../store/userStore";
import { useTranslation } from "react-i18next";
import FeedbackDialog from "../../../../pages/ControlPanel/AdministrativeTools/Feedback/FeedbackDialog";
import { useState } from "react";

export default function AccountPopover() {
  const iam = useIAMPermission();
  const { t } = useTranslation();
  const { profile } = useProfileStore();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [openFeedback, setOpenFeedback] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  let navigate = useNavigate();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const LogOut = () => {
    localStorage.removeItem(`${getTenantId()}_token`);
    axios.defaults.headers.common = {
      Authorization: "",
      "X-TenantID": getTenantId(),
    };
    enqueueSnackbar(t("notification.logOut"), {
      variant: "info",
    });
    navigate("/login");
  };

  const handleAction = (actionID: ProfileActionType) => {
    switch (actionID) {
      case ProfileActionType.feedback:
        setOpenFeedback(true);
        break;
      case ProfileActionType.logout:
        LogOut();
        break;
      default:
        break;
    }
  };

  return (
    <div>
      <Tooltip title={`${profile.firstname} ${profile.lastname}`} arrow>
        <IconButton
          color={"primary"}
          id="demo-positioned-button"
          aria-controls={open ? "demo-positioned-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
        >
          <Avatar alt="avatar" sx={{ width: 24, height: 24, bgcolor: "primary.main", color: "primary.contrastText" }} />
        </IconButton>
      </Tooltip>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        PaperProps={{
          style: {
            marginTop: 8,
          },
        }}
      >
        <MenuList>
          {actions.map((action, index) => {
            const {
              Icon,
              type,
              name,
              divider,
              disabled,
              textColor,
              permissions,
              path
            } = action;

            if (permissions && !iam.hasSome(permissions)) return null;

            if (divider) {
              return <Divider key={index} component={"li"} />;
            }
            const optionName = t(name ?? "");

            if (type === ProfileActionType.account || type === ProfileActionType.console || type === ProfileActionType.releases || type === ProfileActionType.documentation) {
              return (
                <Link to={`${path}`} replace={!!ProfileActionType.documentation} style={{ textDecoration: "none", fontStyle: "bold", color: "inherit" }}>
                  <MenuItem disabled={disabled} onClick={((e) => {
                    handleClose()
                  })}>
                      {Boolean(Icon) && (
                        <ListItemIcon>
                          {/* @ts-ignore */}
                          <Icon fontSize={"medium"} color={textColor} />
                        </ListItemIcon>
                      )}
                      <ListItemText>
                        <Typography variant={"caption"} color={textColor}>
                          {optionName}
                        </Typography>
                      </ListItemText>
                  </MenuItem>
                </Link>
              )
            }

            return (
              <MenuItem
                disabled={disabled}
                onClick={(e) => {
                  e.stopPropagation();
                  handleClose();
                  // @ts-ignore
                  handleAction(type);
                }}
                key={index}
              >
                {Boolean(Icon) && (
                  <ListItemIcon>
                    {/* @ts-ignore */}
                    <Icon fontSize={"medium"} color={textColor} />
                  </ListItemIcon>
                )}
                <ListItemText>
                  <Typography variant={"caption"} color={textColor}>
                    {optionName}
                  </Typography>
                </ListItemText>
              </MenuItem>
            );
          })}
        </MenuList>
      </Menu>
      <FeedbackDialog
        open={openFeedback}
        handleClose={() => setOpenFeedback(false)}
      />
    </div>
  );
}
