import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import {ActualDateTime} from "../../../utils/utils";
import Job, {JobActionDetailType, JobState} from "../../../models/Job";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import DownloadIcon from "@mui/icons-material/Download";
import {Link} from "react-router-dom";
import * as React from "react";
import {useTranslation} from "react-i18next";
import {User} from "../../../models/User";
import {lazy, Suspense, useMemo} from "react";
const JobJsonViewers = lazy(() => import('./JobJsonViewers'));

type RecordProps = {
  label: string;
  value?: string;
};

function Record(props: RecordProps) {
  const { label, value } = props;

  return (
    <Stack
      flexDirection={"column"}
      rowGap={0.5}
      alignItems={"flex-start"}
      width={"100%"}
    >
      <Typography variant={"subtitle2"} color={"textSecondary"}>
        {label}
      </Typography>
      {value && <Typography variant={"subtitle2"}>{value}</Typography>}
    </Stack>
  );
}

interface Props {
  job: Job
  userMap: Map<string, User>
}

function JobDetailsView(props: Props) {
  const { job, userMap } = props
  const { t } = useTranslation();

  const downloadAction = job.jobActionDetails?.actionDetails.find(x => x.type === JobActionDetailType.download)
  const redirectAction = job.jobActionDetails?.actionDetails.find(x => x.type === JobActionDetailType.redirect)

  const createdByName = useMemo(() => {
    const firstname = userMap.get(job.createdBy)?.firstname ?? "";
    const lastname = userMap.get(job.createdBy)?.lastname ?? "";
    return `${firstname} ${lastname}`;
  }, [job, userMap]);

  return (
    <Stack rowGap={3} pt={2}>
      <Card>
        <Stack rowGap={2} p={2}>
          <Typography variant={"subtitle1"}>General Info</Typography>
          <Divider />
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Record
                label={t("table.createdAt")}
                value={ActualDateTime(job.createdAt)}
              />
            </Grid>
            <Grid item xs={4}>
              <Record
                label={t("table.startAt")}
                value={ActualDateTime(job.startAt)}
              />
            </Grid>
            <Grid item xs={4}>
              <Record
                label={t("table.completedAt")}
                value={ActualDateTime(job.completedAt)}
              />
            </Grid>
            <Grid item xs={4}>
              <Record label={t("table.state")} value={job.state === JobState.PROCESSING ? `${job.state} - ${job.progress}%` : job.state ?? "Unknown"} />
            </Grid>
            <Grid item xs={4}>
              <Record label={t("table.createdBy")} value={createdByName} />
            </Grid>
          </Grid>
        </Stack>
        {(downloadAction || redirectAction) && <Divider />}
        {(downloadAction || redirectAction) && (
          <CardActions sx={{ p: 1 }}>
            {downloadAction && (
              <Button
                startIcon={<DownloadIcon />}
                size={"small"}
                variant={"contained"}
                component={"a"}
                href={downloadAction.value}
                target={"_blank"}
              >
                {t("buttons.download")}
              </Button>
            )}
            {redirectAction && (
              <Button
                variant={"outlined"}
                size={"small"}
                component={Link}
                to={redirectAction.value}
                target={"_blank"}
              >
                {t("buttons.open")}
              </Button>
            )}
          </CardActions>
        )}
      </Card>
      <Suspense fallback={<div>Loading...</div>}>
        <JobJsonViewers job={job} />
      </Suspense>
    </Stack>
  )
}

export default JobDetailsView
