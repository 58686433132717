import * as React from "react";
import {
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import SettingsContainer from "components/Common/SettingsContainer";
import ContentHeader from "components/@lib/ContentHeader";
import Job, { JobState } from "models/Job";
import { useUserList } from "store/userStore";
import { fetchData } from "utils/utils";
import { API_URL } from "utils/config";
import { useMap } from "hooks/common/useMap";
import withAuth from "auth/withAuth";
import { useRunningJobsStore } from "../../../store/runningJobsStore";
import ProgressLoader from "../../../components/@lib/ProgressLoader";
import { transformEnum } from "../../../hooks/jobs/useJobsTableBody";
import JobDetailsView from "../../../components/AdminTools/Jobs/JobDetailsView";

const ActivityDetails = () => {
  const params = useParams();
  const { t } = useTranslation();
  const [job, setJob] = useState(new Job());
  const [users] = useUserList();
  const userMap = useMap(users, "uuid");
  const [inProgress, setInProgress] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const fetchJob = useCallback(
    async (uuid: string) => {
      try {
        const res = await fetchData(`${API_URL}/jobs/${uuid}`);
        setJob(res);
        if (
          res.state === JobState.PROCESSING ||
          res.state === JobState.ENQUEUED
        ) {
          await new Promise((resolve) => setTimeout(resolve, 10000));
          await fetchJob(uuid);
        }
      } catch (ex: any) {
        enqueueSnackbar(ex.message);
      }
    },
    [enqueueSnackbar]
  );

  const timeoutRef = useRef() as MutableRefObject<number>;

  useEffect(() => {
    if (job.state !== JobState.PROCESSING) {
      clearInterval(timeoutRef.current);
    }
  }, [job.state]);

  useEffect(() => {
    if (!params.uuid) return;
    (async () => {
      setInProgress(true);
      await fetchJob(params.uuid!);
      setInProgress(false);
    })();
  }, [fetchJob, params.uuid]);

  const { getAll: getAllRunningJobs } = useRunningJobsStore();

  useEffect(() => {
    getAllRunningJobs();
  }, [getAllRunningJobs]);

  return (
    <SettingsContainer>
      <ProgressLoader inProgress={inProgress} />

      <ContentHeader
        title={transformEnum(job.jobType)}
        breadcrumbs={[
          {
            href: "/store/my-activity",
            name: t("jobs.title"),
          },
        ]}
      />

      <JobDetailsView job={job} userMap={userMap} />
    </SettingsContainer>
  );
};

export default withAuth(ActivityDetails);
