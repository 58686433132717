import { useSnackbar } from "notistack";
import * as React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import SearchNavigation from "components/@lib/Inputs/SearchNavigation";
import ReservedUUID from "../../../../models/ReservedUUID";
import { postData } from "../../../../utils/utils";
import { API_URL } from "../../../../utils/config";

type DialogProps = {
  open: boolean;
  handleClose: () => void;
  guideUuid: string;
};

type objectInfo = {
  uuid: string;
  label: string;
};

function AddShortcutDialog({
  open,
  handleClose,
  guideUuid,
}: DialogProps) {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [isSubmitting, setSubmitting] = useState(false);
  const [selectedContainer, setSelectedContainer] = useState<objectInfo>();

  const handleSubmit = async () => {
    if (!selectedContainer) {
      enqueueSnackbar("Please select a destination", { variant: "error" });
      return;
    }

    if (selectedContainer.uuid === ReservedUUID.MyContentRoot) {
      enqueueSnackbar("You cannot add DATASTREAM at ROOT", {
        variant: "error",
      });
      return;
    }

    setSubmitting(true);
    try {
      await postData(
        `${API_URL}/gd/guides/${guideUuid}/shortcut?to=${selectedContainer.uuid}`,
        ""
      );
      handleClose();
      enqueueSnackbar("The shortcut was added successfully", {
        variant: "success",
      });
    } catch (ex) {
      // @ts-ignore
      enqueueSnackbar(ex.message, { variant: "error" });
    }
    setSubmitting(false);
  };

  return (
    <SearchNavigation
      setSelectedContainer={setSelectedContainer}
      open={open}
      handleClose={handleClose}
      handleSubmit={handleSubmit}
      isSubmitting={isSubmitting}
      actionTitle={t("buttons.submit")}
    />
  );
}

export default AddShortcutDialog;
