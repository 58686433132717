import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { filters } from "./Filters";
import {useTranslation} from "react-i18next";
import {buildParameters} from "../../utils/utils";

type FilterListProps = {
  onFilterClick: (parameters: string) => void;
  handleClose: ()=>void;
};

export default function FilterList({ onFilterClick , handleClose}: FilterListProps) {
  const { t } = useTranslation();

  return (
    <Stack
      direction={"row"}
      alignItems={"center"}
      justifyContent={"space-between"}
      columnGap={1}
      sx={{ m:1, mt:2, mb:2 }}
    >
      {filters.map((filter, index) => (
        <Button
          key={index}
          color={"primary"}
          onClick={() => {
            let params;
            if (filter.type === "CONTAINER" || filter.type === "DATASTREAM") {
              params = buildParameters({
                type: filter.type,
              });
            }else{
              params = buildParameters({
                type: "DATASTREAM",
                filetype: filter.type,
                datastreamTypes: null
              });
            }
            onFilterClick(params)
            handleClose()
          }}
          sx={{width:100}}
        >
          <Stack direction={"column"} rowGap={1} alignItems={"center"}>
            <filter.Icon fontSize={"medium"} />
            <Typography variant={"overline"}> {t(filter.name)} </Typography>
          </Stack>
        </Button>
      ))}
    </Stack>
  );
}
