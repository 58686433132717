import { Fragment, useCallback, useEffect, useState } from "react";
import Container from "models/Container";
import List from "@mui/material/List";
import Button from "@mui/material/Button";
import ListItemButton from "@mui/material/ListItemButton";
import CircularProgress from "@mui/material/CircularProgress";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import { API_URL } from "utils/config";
import { fetchData } from "utils/utils";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import Divider from "@mui/material/Divider";
import * as React from "react";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import { useSnackbar } from "notistack";
import Dialog from "components/@lib/Dialog";
import CloseIcon from "@mui/icons-material/Close";
import useContainerChildren from "hooks/container/useContainerChildren";
import PaginationControl from "pages/Content/Containers/Common/PaginationControl";
import ReservedUUID from "models/ReservedUUID";
import { useTranslation } from "react-i18next";
import ContainerCover from "../../ContainerCover";

export type objectInfo = {
  uuid: string;
  label: string;
};

type SearchNavigationProps = {
  open: boolean;
  handleClose: () => void;
  isSubmitting: boolean;
  handleSubmit: () => void;
  actionTitle: string;
  entityID?: string;
  selectedContainerParent?: objectInfo;
  setSelectedContainer: React.Dispatch<
    React.SetStateAction<objectInfo | undefined>
  >;
  move?: boolean;
};

export default function SearchNavigation({
  entityID,
  setSelectedContainer,
  selectedContainerParent,
  move,
  isSubmitting,
  open,
  handleClose,
  handleSubmit,
  actionTitle,
}: SearchNavigationProps) {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [parentIndex, setParentIndex] = useState<objectInfo>({
    uuid: "",
    label: "",
  });
  const [backIndex, setBackIndex] = useState<objectInfo>({
    uuid: "",
    label: "",
  });
  const [selectedDestination, setSelectedDestination] = useState<objectInfo>();

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setSelectedContainer(selectedDestination!);
  }, [setSelectedContainer, selectedDestination]);

  const { containerResult, getContainers, loadingContainers } =
    useContainerChildren(parentIndex.uuid, open);

  //Change level in container tree
  const handleChangeDestinationLevel = useCallback(
    async (item: objectInfo) => {
      if (!open) return;
      setIsLoading(true);
      try {
        await fetchData(`${API_URL}/containers/${item.uuid}`).then((res) => {
          setParentIndex({ uuid: res.uuid, label: res.label });
          if (res.ancestorPath.length > 0) {
            setBackIndex(res.ancestorPath[0]);
          } else {
            setBackIndex({ uuid: ReservedUUID.MyContentRoot, label: "ROOT" });
          }
        });
      } catch (e) {
        // @ts-ignore
        enqueueSnackbar(e.message);
      }
      setIsLoading(false);
    },
    [enqueueSnackbar, open]
  );

  //Get back to previous tree level
  const handleBack = () => {
    if (backIndex.uuid === ReservedUUID.MyContentRoot) {
      //setContainerIndexes(containers);
      setBackIndex({ uuid: ReservedUUID.MyContentRoot, label: "ROOT" });
      setParentIndex({ uuid: ReservedUUID.MyContentRoot, label: "ROOT" });
    } else {
      setIsLoading(true);
      handleChangeDestinationLevel(backIndex).then(() => setIsLoading(false));
    }
  };

  //Set tree on first open
  useEffect(() => {
    setSelectedDestination({ uuid: "", label: "" });
    if (!selectedContainerParent?.uuid) {
      setParentIndex({ uuid: ReservedUUID.MyContentRoot, label: "ROOT" });
      setBackIndex({ uuid: ReservedUUID.MyContentRoot, label: "ROOT" });
    } else {
      setIsLoading(true);
      handleChangeDestinationLevel(selectedContainerParent).then(() =>
        setIsLoading(false)
      );
    }
  }, [selectedContainerParent, handleChangeDestinationLevel]);

  //Set selected destination from list
  const handleListItemClick = (item: Container) => {
    setSelectedDestination({ uuid: item.uuid, label: item.label });
  };

  //Set selected destination from list
  const handleParentClick = () => {
    setSelectedDestination(parentIndex);
  };

  const isSelected = (uuid: string) => {
    return selectedDestination?.uuid === uuid;
  };

  const renderTitle = () => (
    <Stack
      flexDirection={"row"}
      alignItems={"center"}
      justifyContent={"flex-start"}
      mt={1}
      mb={1}
    >
      {isLoading ? (
        <Typography variant={"caption"} sx={{ ml: 1 }}>
          {t("search.loading")}
        </Typography>
      ) : (
        <Fragment>
          {parentIndex.label !== "ROOT" && (
            <IconButton
              size={"small"}
              onClick={handleBack}
              color={"inherit"}
              sx={{ p: 0, ml: 1 }}
            >
              <KeyboardBackspaceIcon fontSize={"small"} />
            </IconButton>
          )}
          <Tooltip title={"Select parent container"} arrow placement={"top"}>
            <Button
              style={{ textTransform: "none" }}
              sx={{ ml: 1 }}
              onClick={handleParentClick}
              color={"inherit"}
            >
              <Typography variant={"caption"}>
                {parentIndex.label === "ROOT" ? t("root") : parentIndex.label}
              </Typography>
            </Button>
          </Tooltip>
        </Fragment>
      )}

      <div style={{ flexGrow: 1 }}></div>
      <IconButton
        size={"small"}
        aria-label="close"
        onClick={handleClose}
        sx={{
          mr: 1,
          color: (theme) =>
            theme.palette.mode === "dark"
              ? theme.palette.common.white
              : theme.palette.common.black,
        }}
      >
        <CloseIcon fontSize={"small"} />
      </IconButton>
    </Stack>
  );

  return (
    <Dialog
      open={open}
      handleClose={handleClose}
      maxWidth={"sm"}
      inProgress={isSubmitting}
      renderTitle={renderTitle}
      fullWidth
    >
      <Stack
        flexDirection={"column"}
        alignItems={"flex-start"}
        rowGap={1}
        mt={-1}
        ml={-1}
        mr={-1}
      >
        <Paper style={{ height: 350, overflow: "auto", width: "100%" }}>
          {loadingContainers ? (
            <Stack alignItems={"center"} justifyContent={"center"}>
              <CircularProgress />
            </Stack>
          ) : (
            <List>
              {containerResult.content.length > 0 ? (
                <Fragment>
                  {" "}
                  {containerResult.content.map((container, index) => (
                    <ListItemButton
                      key={index}
                      onClick={() => handleListItemClick(container)}
                      disabled={!!(move && entityID === container.uuid)}
                      onDoubleClick={() =>
                        handleChangeDestinationLevel({
                          uuid: container.uuid,
                          label: container.label,
                        })
                      }
                      sx={{
                        color: isSelected(container.uuid)
                          ? "primary.main"
                          : "inherit",
                        "&:hover": {
                          backgroundColor: "secondary.main",
                        },
                      }}
                    >
                      <ListItemIcon>
                        <ContainerCover size={36} />
                      </ListItemIcon>
                      <ListItemText>
                        <Typography variant={"body2"}>
                          {" "}
                          {container.label}
                        </Typography>{" "}
                      </ListItemText>
                    </ListItemButton>
                  ))}
                </Fragment>
              ) : (
                <Stack direction={"row"} justifyContent={"center"} mt={12}>
                  <Typography variant={"caption"} color={"textSecondary"}>
                    There is no container included
                  </Typography>
                </Stack>
              )}
              {containerResult.totalPages > 1 && (
                <Stack p={2} justifyContent={"center"} direction={"row"}>
                  <PaginationControl
                    disabled={loadingContainers}
                    count={containerResult.totalPages}
                    page={containerResult.number + 1}
                    onPageChange={getContainers}
                    size={"small"}
                  />
                </Stack>
              )}
            </List>
          )}
        </Paper>
        <Divider
          orientation={"horizontal"}
          flexItem
          sx={{ ml: -1, mr: -1 }}
        ></Divider>
      </Stack>

      <Stack
        direction={"row"}
        columnGap={1}
        alignItems={"center"}
        sx={{ ml: -1, mr: -2, mt: 2 }}
      >
        <Typography variant={"caption"} color={"primary"}>
          {t("to")}
        </Typography>
        <DoubleArrowIcon sx={{ color: "primary.main", fontSize: 14 }} />
        <Typography variant={"caption"} color={"primary"}>
          {selectedDestination?.label === "ROOT" ? t("root") : selectedDestination?.label}
        </Typography>
        <div style={{ flex: 1 }}></div>

        <Button
          variant={"contained"}
          type={"submit"}
          onClick={handleSubmit}
          autoFocus
          size={"small"}
          disabled={!selectedDestination?.label}
        >
          {actionTitle}
        </Button>
      </Stack>
    </Dialog>
  );
}
