import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import { TimelineOppositeContent } from "@mui/lab";
import Typography from "@mui/material/Typography";
import { ActualDate, ActualTime, fetchData, postData } from "../../../../utils/utils";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import { Paper, Skeleton, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { API_URL } from "../../../../utils/config";
import { useTranslation } from "react-i18next";
import Dialog from "../../../../components/@lib/Dialog";
import { useSnackbar } from "notistack";


interface Props {
  open: boolean;
  handleClose: () => void;
  guideUUID: string;
}

function ActivityDialog({ open, handleClose, guideUUID }: Props) {

  const { t } = useTranslation();
  const [inProgress, setInProgress] = useState(false);
  const [eventTypes, setEventTypes] = useState<any>();
  const [activityData, setActivityData] = useState<any>();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (!guideUUID) return;
    fetchData(`${API_URL}/gd/guides/${guideUUID}/activity/event_types`)
      .then((data) => {
        setEventTypes(data);
      })
      .catch((ex) => {
        enqueueSnackbar(ex.message, { variant: "error" });
      });
  }, [open, guideUUID, enqueueSnackbar]);

  useEffect(() => {
    if (!guideUUID) return;
    if (eventTypes) {
      postData(`${API_URL}/gd/guides/${guideUUID}/activity`, { eventTypes })
        .then((data) => {
          setInProgress(false);
          setActivityData(data);
        })
        .catch((ex) => {
          enqueueSnackbar(ex.message, { variant: "error" });
          setInProgress(false);
        });
    }
  }, [eventTypes, guideUUID, enqueueSnackbar]);

  return (
    <Dialog
      open={open}
      maxWidth={"xs"}
      fullWidth
      handleClose={handleClose}
      secondaryAction={handleClose}
      inProgress={inProgress}
      title={t("activity.header").toString()}
      primaryActionTitle={t("buttons.ok").toString()}
    >
      <Stack justifyContent={"center"} direction={"row"}>
        {(inProgress || !activityData) ? (
          <>
            <Timeline sx={{ py: 0, pt: 2 }}>
              {[0,1,2,3].map((event:any, index:number) => (
                <TimelineItem style={{ margin: 0, padding: 0 }} key={index}>
                  <TimelineOppositeContent sx={{ py: 0, px: 1 }}>
                    <Typography variant="body2" color="textSecondary">
                      <Skeleton variant={"rectangular"} height={12} width={150} />
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      <Skeleton variant={"rectangular"} height={12} width={80} />
                    </Typography>
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <ArrowCircleRightOutlinedIcon
                      fontSize={"medium"}
                      color={"primary"}
                    />
                    <TimelineConnector sx={{ backgroundColor: "secondary.main" }} />
                  </TimelineSeparator>
                  <TimelineContent sx={{ py: 0, pb: 4, pl: 2, width: 150 }}>
                    <Paper elevation={4} sx={{ height: "auto", width: 140 }}>
                      <Typography variant="body2" sx={{ p: 1 }}>
                        <Skeleton variant={"rectangular"} height={12} width={120} />
                      </Typography>
                    </Paper>
                  </TimelineContent>
                </TimelineItem>
              ))}

            </Timeline>
          </>
        ) : (
          <Timeline sx={{ py: 0, pt: 2 }}>

            {Boolean(activityData)&&activityData?.content.map((event:any, index:number) => (
              <TimelineItem style={{ margin: 0, padding: 0 }} key={index}>
                <TimelineOppositeContent sx={{ py: 0, px: 1 }}>
                  <Typography variant="body2" color="textSecondary">
                    {ActualDate(event.createdAt)}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {ActualTime(event.createdAt)}
                  </Typography>
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <ArrowCircleRightOutlinedIcon
                    fontSize={"medium"}
                    color={"primary"}
                  />
                  <TimelineConnector sx={{ backgroundColor: "secondary.main" }} />
                </TimelineSeparator>
                <TimelineContent sx={{ py: 0, pb: 4, pl: 2, width: 150 }}>
                  <Paper elevation={4} sx={{ height: "auto", width: 140 }}>
                    <Typography variant="body2" sx={{ p: 1 }}>
                      {event.message}
                    </Typography>
                  </Paper>
                </TimelineContent>
              </TimelineItem>
            ))}

          </Timeline>
        )}
      </Stack>
    </Dialog>
  );
}

export default ActivityDialog
