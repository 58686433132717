import { GuideLayoutType } from "./GuideLayoutType";
import {API_URL} from "../../../utils/config";
import axios from "axios";
import {languages} from "./Languages";

class overviewObject {
  itemCount: number = 0;
  listCount: number = 0;
  pageCount: number = 0;
  routeCount: number = 0;
}

class GuideApplication {
  uuid: string = "";
  label: string = "";
  description: string = "";
  languages: string[] = [languages[0].code];
  defaultLanguage: string = languages[0].code;
  createdAt: string = "";
  createdBy: string = "";
  updatedAt: string = "";
  updatedBy: string = "";
  coverImage?: string | null;
  overview: overviewObject = new overviewObject();
  iBeaconUuid?: string

  static getByID(id: string) {
    return axios
      .get<GuideApplication>(`${API_URL}/gd/guides/${id}`)
      .then((res) => res.data)
  }

  static getByIDAndLanguage(id: string,selectedLanguage:string) {
    return axios
        .get<GuideApplication>(`${API_URL}/gd/guides/${id}?lang=${selectedLanguage}`)
        .then((res) => res.data)
  }

}

export class CreateGuideApplication extends GuideApplication {
  layout: GuideLayoutType = GuideLayoutType.TAB;
}

export default GuideApplication;
