import DefaultCover from "../../../../../assets/images/default_image.png";
import {VideoAssetType, VideoDataType} from "../../../../../extensions/guide/models/AssetType";
import Card from "@mui/material/Card";
import {Box, CardMedia, IconButton, Stack, Tooltip} from "@mui/material";
import TextField from "@mui/material/TextField";
import React, {Dispatch, SetStateAction, useEffect, useMemo, useState} from "react";
import { useTranslation } from "react-i18next";
import CardActions from "@mui/material/CardActions";
import CollectionsIcon from "@mui/icons-material/Collections";
import CloseOutlined from "@mui/icons-material/CloseOutlined";
import DefaultLink from "../../../../../assets/images/default_link.png";
import {MediaAsset, MediaAssetSize} from "../../../../../models/MediaAsset";
import axios from "axios";
import Fade from "@mui/material/Fade";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import AssetDownloadButton from "./AssetDownloadButton";
import EditIcon from "@mui/icons-material/Edit";

type Asset = { uuid: string; caption: string };

interface AssetItemCardProps {
  asset: Asset | string | VideoAssetType;
  captioned: boolean;
  onRemove: (uuid: string) => void;
  onCaptionChange: (uuid: string, caption: string) => void;
  setOpenImagePicker: Dispatch<SetStateAction<boolean>>;
  onClick?: (uuid: string) => void;
  isLast?: boolean;
  isFirst?: boolean;
  handleReorder?: (uuid: string, direction: 1 | -1) => void;
  maxFiles?: number;
  currentFileCount?: number;
}

function getYoutubeThumbnail(url:string) {
  let thumbnailUrl = '';

  try {
    const videoId = url.split('https://www.youtube.com/embed/')[1];
    thumbnailUrl = `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`;
  } catch (e) {
    return DefaultLink;
  }

  return thumbnailUrl;
}

const useValidUrl = (uuid: string) => {
  const [isValid, setIsValid] = useState(false)

  useEffect(() => {
    (async () => {
      try {
        const url = MediaAsset.getMediaUrl(uuid, MediaAssetSize.SM);
        await axios.get(url, { responseType: 'blob' });
        setIsValid(true)

      } catch (error) {
        setIsValid(false)
      }
    })()

  }, [uuid]);

  return isValid
}

function AssetItemCard({
  asset,
  captioned,
  onRemove,
  onCaptionChange,
  onClick,
  handleReorder,
  isFirst,
  isLast,
  setOpenImagePicker,
  maxFiles,
  currentFileCount
}: AssetItemCardProps) {
  const [hovered, setHovered] = React.useState(false);

  const { t } = useTranslation();
  // @ts-ignore
  const uuid = captioned ? asset.uuid || asset.data : asset;
  // @ts-ignore
  const caption = captioned ? asset.caption : "";
  // @ts-ignore
  const isVideoLink = asset?.source === VideoDataType.ASSET;
  // @ts-ignore
  const videoSrc = asset?.data ? (asset?.data.includes('youtube.com') && (!asset?.data.includes('channel'))) ? getYoutubeThumbnail(asset.data) : DefaultLink : DefaultLink

  const mediaAsset = useMemo(() => {
    const mediaAsset = { bitstreamFile: {} } as MediaAsset;
    mediaAsset.bitstreamFile.viewUrl = MediaAsset.getMediaUrl(uuid);
    mediaAsset.label = MediaAsset.name;
    return mediaAsset;
  }, [uuid]);

  const isValidURL = useValidUrl(uuid);

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  if (!uuid) return null;

  return (
    <Stack direction={"row"} alignItems={"center"} justifyContent={"center"}>
      {handleReorder && (
        <Fade in={hovered}>
          <IconButton
            disabled={isFirst}
            onClick={() => handleReorder(uuid, -1)}
          >
            <KeyboardArrowLeft />
          </IconButton>
        </Fade>
      )}
      <Card
        key={uuid}
        sx={{
          width: isVideoLink ? 200 : 180,
          height: 220,
          overflow: "unset",
        }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={() => {
          if (isVideoLink && onClick) onClick(uuid);
        }}
        style={{
          cursor: isVideoLink ? "pointer" : "default",
        }}
      >
        <CardMedia
          sx={{ height: captioned ? "80%" : "100%" }}
          image={
            isVideoLink
              ? videoSrc
              : isValidURL
              ? MediaAsset.getMediaUrl(uuid, MediaAssetSize.SM)
              : DefaultCover
          }
        >
          <Box
            sx={{
              position: "absolute",
              borderRadius: 2,
              top: 10,
              left: 10,
            }}
            bgcolor={
              !isValidURL ? "rgba(250, 250, 250, 0.8)" : "rgba(0, 0, 0, 0.5)"
            }
          >
            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"center"}
              p={0.5}
            >
              <Tooltip
                title={
                  maxFiles === 1 && maxFiles === currentFileCount
                    ? t("assetpicker.selectAsset")
                    : t("assetpicker.changeAsset")
                }
              >
                <IconButton
                  aria-label="add"
                  onClick={() => setOpenImagePicker(true)}
                >
                  <CollectionsIcon
                    fontSize={"small"}
                    sx={{
                      color: !isValidURL
                        ? "primary.main"
                        : "primary.contrastText",
                    }}
                  />
                </IconButton>
              </Tooltip>
              <AssetDownloadButton asset={mediaAsset} disabled={!isValidURL} />
              {isVideoLink && (
                <EditIcon
                  fontSize={"small"}
                  sx={{
                    color: !isValidURL
                      ? "primary.main"
                      : "primary.contrastText",
                  }}
                />
              )}
            </Stack>
          </Box>
          <IconButton
            sx={{
              backgroundColor: "background.neutral",
              position: "absolute",
              top: 0,
              right: 0,
              mr: -1.75,
              mt: -1.75,
              boxShadow: 1,
              ":hover": {
                backgroundColor: "background.neutral",
                boxShadow: 2,
              },
            }}
            size={"small"}
            onClick={() => onRemove(uuid)}
          >
            <CloseOutlined fontSize={"small"} />
          </IconButton>
        </CardMedia>
        {captioned && (
          <CardActions
            sx={{
              width: "100%",
              height: "20%",
              p: 0,
              objectFit: "cover",
              borderBottomLeftRadius: 3,
              borderBottomRightRadius: 3,
            }}
          >
            <TextField
              size={"small"}
              value={caption}
              onChange={(e) => onCaptionChange(uuid, e.target.value)}
              placeholder={t("assetpicker.caption").toString()}
              fullWidth
              sx={{
                height: "100%",
                borderRadius: "0px 0px 3px 3px",
                "& .MuiInputBase-root": {
                  borderRadius: "0px 0px 3px 3px",
                  height: "100%",
                },
                // "& .MuiTextField-root.Mui-focusVisible": {
                //   outline: "none"
                // }
              }}
            />
          </CardActions>
        )}
      </Card>
      {handleReorder && (
        <Fade in={hovered}>
          <IconButton disabled={isLast} onClick={() => handleReorder(uuid, 1)}>
            <KeyboardArrowRight />
          </IconButton>
        </Fade>
      )}
    </Stack>
  );
}

export default AssetItemCard;
