import Dialog from "../@lib/Dialog";
import { useTranslation } from "react-i18next";
import { Skeleton, Stack } from "@mui/material";
import useQRCode from "../../extensions/guide/hooks/useQRCode";

interface Props {
  open: boolean;
  handleClose: () => void;
  url: string;
}

function QRCodeDialog({ open, handleClose, url }: Props) {

  const {
    qrCodeData,
    inProgress
  } = useQRCode(url, open)

  const { t } = useTranslation();

  return (
    <Dialog
      open={open}
      maxWidth={"xs"}
      fullWidth
      handleClose={handleClose}
      secondaryAction={handleClose}
      inProgress={inProgress}
      title={t("guides.header.getQRCode").toString()}
    >
      <Stack justifyContent={"center"} direction={"row"}>
        {inProgress ? (
          <Skeleton variant={"rectangular"} height={200} width={200} />
        ) : (
          <img alt={"QR Code"} src={qrCodeData} width={200} height={200} />
        )}
      </Stack>
    </Dialog>
  );
}

export default QRCodeDialog;
