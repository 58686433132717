import TagsType from "./TagsType";
import {PropertyHolder} from "./Container";
import {RepoxType} from "./RepoxType";

export class DatastreamNew {
  label: string = "";
  type: string = "";
  properties: Details[] = [];
}

export class BitstreamFile {
  uuid: string = "";
  viewUrl: string = "";
  externalEditorUrl: string = "";
  filename: string = "";
  filesize: number = 0;
  mimeType: string = "";
  createdAt: string = "";
  signature: object = {};
  thumbnails : Thumbnails = new Thumbnails();
}

export class FileInfo {
  filename: string = "";
  filesize: number = 0;
  mimeType: string = "";
  technicalDetails: object = {};
}

class Thumbnails {
  largeUrl: string = "";
  mediumUrl: string = "";
  smallUrl: string = "";
}

export class Datastream extends PropertyHolder {
  uuid: string = "";
  label: string = "";
  type: Type = {
    uuid: "",
    name: "",
  };
  typeName: string = "";
  parent: string = "";
  createdAt: string = "";
  createdBy: CreatedBy = {};
  updatedAt: string = "";
  updatedBy: UpdatedBy = {};
  publishedAt: string = "";
  publishedBy: PublishedBy = {};
  textExtracted: boolean = false;
  textChunked: boolean = false;
  repoxType: string = "";
  objectType: string = "";
  objectId: string = "";
  tags: TagsType[] = [];
  ancestorPath: Path[] = [];
  bitstreamFile = new BitstreamFile();
  setAsCover = false;
  shared = false;

  static getTypeName(ds: Datastream): string {
    if (ds.type) return ds.type.name
    switch (ds.repoxType) {
      case RepoxType.REPOX_LINK: return "Shortcut"
      case RepoxType.REPOX_GUIDE: return "Guide"
      case RepoxType.REPOX_GEO: return "Geo"
      case RepoxType.REPOX_SURVEY: return "Survey"
      case RepoxType.REPOX_STORY: return "Story"
      default: return "Unknown"
    }
  }
}

type Type = {
  uuid: string;
  name: string;
};

export type CreatedBy = {
  uuid?: string;
  firstname?: string;
  lastname?: string;
  username?: string;
  email?: string;
};

export type UpdatedBy = {
  uuid?: string;
  firstname?: string;
  lastname?: string;
  username?: string;
  email?: string;
};

type PublishedBy = {
  uuid?: string;
  firstname?: string;
  lastname?: string;
  username?: string;
  email?: string;
};

class Details {
  uuid: string = "";
  propertyUuid: string = "";
  value: {} = {};
}

class Path {
  uuid: string = "";
  label: string = "";
  publishedAt: string = "";
}

export class FlatDatastreamNew {
  label: string = "";
  type: string = "";
}
export class FullTextSearchDatastream extends Datastream {
  fullTextResult : FullTextResult ={};
}

export class DatastreamText {
  content: string = "There is no text";
  createdAt: string = "";
}

export type FullTextResult  = {
  score?: number,
  highlightedFields?: string[],
  highlightedSize?: number,
}
