import React, { Fragment, useState } from "react";
import { IconButton, Stack, Tooltip, Typography } from "@mui/material";
import LanguagePicker from "components/Navigation/NavigationActions/LanguageNavItem";
import RepoxApps from "components/Navigation/NavigationActions/RepoxAppsNavItem";
import NotificationIcon from "components/Navigation/NavigationActions/NotificationsNavItem";
import AccountNavItem from "components/Navigation/NavigationActions/AccountNavItem";
import ThemeSelectionNavItem from "components/Navigation/NavigationActions/ThemeSelectionNavItem";
import useGuide from "../../hooks/useGuide";
import AddLinkIcon from "@mui/icons-material/AddLink";
import QrCodeIcon from "@mui/icons-material/QrCode";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useTranslation } from "react-i18next";
import QRCodeDialog from "../../../../components/QRCodeDialog";
import { API_URL } from "../../../../utils/config";
import ActivityDialog from "../ActivityDialog/ActivityDialog";
import AddShortcutDialog from "../../pages/ApplicationDetails/AddShortcutDialog";
import MyActivityNavItem from "../../../../components/Navigation/NavigationActions/MyActivityNavItem";

const Header = () => {
  const { guide } = useGuide();

  const { t } = useTranslation();
  const [openQR, setOpenQR] = useState(false);
  const [openActivity, setOpenActivity] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  const handleQRCode = () => {
    setOpenQR(true);
  };

  const handleOpenActivityDialog = () => {
    setOpenActivity(true);
  };

  return (
    <Fragment>
      {guide.uuid && <Stack direction={"row"} columnGap={0.5} alignItems={"center"} pt={1}>
        <Typography variant={"h6"}>{guide.label} &nbsp;</Typography>
        <Tooltip title={t("guides.header.createShortcut").toString()}>
          <IconButton onClick={() => setOpenDialog(true)} size={"small"}>
            <AddLinkIcon color={"action"} fontSize={"small"} />
          </IconButton>
        </Tooltip>
        <Tooltip title={t("guides.header.getQRCode").toString()}>
          <IconButton onClick={handleQRCode} size={"small"}>
            <QrCodeIcon color={"action"} fontSize={"small"} />
          </IconButton>
        </Tooltip>
        <Tooltip title={t("activity.header").toString()}>
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              handleOpenActivityDialog();
            }}
            size={"small"}
          >
            <InfoOutlinedIcon color={"action"} fontSize={"small"} />
          </IconButton>
        </Tooltip>
      </Stack>}
      <Stack
        ml={"auto"}
        flexDirection={"row"}
        columnGap={1}
        alignItems={"center"}
      >
        <ThemeSelectionNavItem />
        <LanguagePicker />
        <RepoxApps />
        <MyActivityNavItem/>
        <NotificationIcon />
        <AccountNavItem />
      </Stack>

      <QRCodeDialog
        open={openQR}
        handleClose={() => setOpenQR(false)}
        url={`${API_URL}/gd/guides/${guide.uuid}/qr`}
      />
      <ActivityDialog
        open={openActivity}
        handleClose={() => setOpenActivity(false)}
        guideUUID={guide.uuid as string}
      />
      <AddShortcutDialog
        guideUuid={guide.uuid}
        open={openDialog}
        handleClose={() => setOpenDialog(false)}
      />
    </Fragment>
  );
};

export default Header;
