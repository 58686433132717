import { MediaAsset } from "../../../models/MediaAsset";
import CardActions from "@mui/material/CardActions";
import Checkbox from "@mui/material/Checkbox";
import Card from "@mui/material/Card";
import CircularProgress from "@mui/material/CircularProgress";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import * as React from "react";
import DefaultCover from "../../../assets/images/default_image.png";
import { Download, Edit } from "@mui/icons-material";
import useImageDownload from "../../../extensions/guide/hooks/useImageDownload";
import moment from "moment-timezone";
import FileIcon from "../../@lib/Graphics/FileIcon";
import VideoFileIcon from "../../@lib/Graphics/VideoFileIcon";
import AudioFileIcon from "../../@lib/Graphics/AudioFileIcon";
import { Box } from "@mui/material";
import {styled} from "@mui/styles";
import {FileType} from "../../../models/Import";
import {Dispatch, SetStateAction, useState} from "react";

type MediaCardProps = {
  asset: MediaAsset;
  checked?: boolean;
  onCheck?: (uuid: string) => void;
  checkable?: boolean;
  onEdit?: (asset: MediaAsset) => void;
  fileType?: FileType
};

const StyledBox = styled(Box)(({ theme }) => ({
  margin: "auto",
  padding: 8,
  maxWidth: 120
}));


interface CoverImageProps {
  url: string;
  fileType?: FileType;
  setError: Dispatch<SetStateAction<boolean>>;
  error: boolean;
}

function CoverImage(props: CoverImageProps) {
  const { url, fileType, error, setError  } = props;

  switch (fileType) {
    case "FILE":
      return (
        <StyledBox>
          <FileIcon />
        </StyledBox>
      );
    case "VIDEO":
      return (
        <StyledBox>
          <VideoFileIcon />
        </StyledBox>
      );
    case "AUDIO":
      return (
        <StyledBox>
          <AudioFileIcon />
        </StyledBox>
      );
    default:
      break;
  }

  const image = url && !error ? url : DefaultCover;

  return (
    <div style={{ position: "relative", paddingTop: "85%" }}>
      <img
        onError={() => setError(true)}
        src={image}
        alt={""}
        style={{ position: "absolute", top: 0, left: 0, height: "100%", width: "100%", objectFit: "cover" }}
      />
    </div>
  );
}

function MediaCard(props: MediaCardProps) {
  const { asset, onCheck, checked, fileType, checkable, onEdit } = props;
  const timezone = localStorage.getItem("timezone") || "Europe/Athens"
  const [error, setError] = useState(false)

  const [downloadImage, isDownloading] = useImageDownload(asset)

  return (
    <Card
      onClick={() => {
        if (onCheck && checkable) {
          onCheck(asset.uuid);
        }
      }}
      sx={{
        cursor: checkable ? "pointer" : "inherit",
        borderColor: checked ? "primary.main" : "divider",
        transition: "all 0.3s",
      }}
    >
      <CoverImage
        url={asset.bitstreamFile.thumbnails.mediumUrl}
        setError={setError}
        error={error}
        fileType={fileType}
      />
      {checkable && onCheck && (
        <Checkbox
          sx={{
            backgroundColor: "background.paper",
            color: "primary",
            position: "absolute",
            left: 0,
            m: 1,
            top: 0,
            "&:hover": {
              backgroundColor: "background.neutral",
            },
          }}
          checked={checked}
          size={"small"}
        />
      )}
      <Divider />
      <CardActions>
        <Stack rowGap={0.5} overflow={"hidden"} flex={1}>
          <Stack direction={"row"} alignItems={"center"} flex={1}>
            <Typography noWrap flex={1} sx={{ mr: 1 }}>
              {asset.label}
            </Typography>
            <IconButton
              size={"small"}
              disabled={isDownloading}
              onClick={(e) => {
                e.stopPropagation();
                downloadImage()
              }}
            >
              {isDownloading ? <CircularProgress size={16}/> : <Download fontSize={"small"} />}
            </IconButton>
            {onEdit && (
              <IconButton
                size={"small"}
                onClick={(e) => {
                  e.stopPropagation();
                  onEdit(asset);
                }}
              >
                <Edit fontSize={"small"} />
              </IconButton>
            )}
          </Stack>
          <Typography variant={"caption"} color={"textSecondary"}>
            {moment.utc(asset.createdAt).tz(timezone).format("LLL")}
          </Typography>
        </Stack>
      </CardActions>
    </Card>
  );
}

// useDatastreamFileImage
export default MediaCard;
