import Box from "@mui/material/Box";
import FolderIcon from "../@lib/Graphics/FolderIcon";
import React, {useState} from "react";
import { CoverFile } from "../../models/Container";
import Tooltip from "@mui/material/Tooltip";

interface Props {
  size: 36 | 48 | 60;
  coverFile?: CoverFile;
}

function ContainerCover({ coverFile, size }: Props) {
  const [imageError, setImageError] = useState(false);

  const imageUrl = coverFile?.thumbnails.mediumUrl

  return imageUrl && !imageError ? (
    <Tooltip
      title={
        <img
          src={imageUrl}
          alt={""}
          style={{ borderRadius: 8 }}
        />
      }
      placement="right"
      style={{ maxWidth: "400px" }}
      arrow
    >
      <img
        src={imageUrl}
        alt={""}
        onError={() => setImageError(true)}
        style={{
          width: size,
          height: size,
          objectFit: "cover",
          borderRadius: 8,
        }}
      />
    </Tooltip>
  ) : (
    <Box sx={{ width: size, height: size, minWidth: size }}>
      <FolderIcon />
    </Box>
  );
}

export default ContainerCover;
